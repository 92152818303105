<template>
  <div class="main-container">
    <!-- Navigation Section -->
    <div class="nav">
      <p class="title">Welcome to NexLaw!</p>
      <p>Discover how to get started by watching our onboarding videos.</p>
    </div>

    <!-- Loading and No Videos Section -->
    <div class="content" v-if="isLoading">
      Loading videos...
    </div>
    <div class="content" v-else-if="!videos.length">
      No videos available.
    </div>

    <!-- Video List and Player Section -->
    <div class="content" v-else>
      <!-- Sidebar: Video List -->
      <aside class="sidebar">
        <p class="sidetext">Explore with NexLaw</p>
        <p class="c_text">Checkout these tutorial videos.</p>

        <!-- Video List -->
        <div class="list mt-4">
          <div v-for="video in videos" :key="video.id" @click="setCurrentVideo(video)" class="con_v">
            <span class="material-symbols-rounded">{{ video.icon }}</span>
            <div class="card_side">
              <p class="v-title">{{ video.title }}</p>
              <p class="sub-title">Welcome aboard to NexLaw</p>
            </div>
          </div>
        </div>
      </aside>

      <!-- Main Video Section -->
      <section class="video-section">
        <div class="video-display" v-if="currentVideo">
          <div class="video-item">
            <span class="material-symbols-rounded">{{ currentVideo.icon }}</span>
            <p class="video-title mt-1">{{ currentVideo.title }}</p>
          </div>

          <!-- Video Player -->
          <video ref="videoPlayer" controls @error="handleVideoError">
            <source :src="getVideoSrc(currentVideo.file)" type="video/mp4">
            Your browser does not support the video tag.
          </video>

          <!-- Next Video Button -->
          <button @click="nextVideo" class="next-btn" aria-label="Next Video">
            <span class="next-text">Next</span>
            <span class="material-symbols-rounded arrow-icon">chevron_right</span>
          </button>

        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videos: [],
      currentVideo: null,
      isLoading: true,
    };
  },

  mounted() {
    this.fetchVideos();
  },

  methods: {
    // Simulate API Call to Fetch Videos
    fetchVideos() {
      setTimeout(() => {
        this.videos = [
          { id: 1, icon: 'flag', title: 'Welcome aboard to NexLaw', file: 'https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/Nexlaw+Onboarding+Video.mp4' },
          { id: 2, icon: 'grid_view', title: 'NexLaw Overview', file: 'https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/v5/NexLaw+Video+-+Dashboard+Overview_V2.mp4'},
          { id: 3, icon: 'gavel', title: 'Legal AI Trial Copilot', file: 'https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/NexLaw+Video+-+Case+law+Search+2024.mp4' },
          { id: 4, icon: 'balance', title: 'Law Features', file: 'https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/NexLaw+Video+-+Legislation+Search_Jan+24.mp4' },
          { id: 5, icon: 'settings', title: 'Other Configurations', file: 'https://nexmind-public-resources.s3.ap-southeast-1.amazonaws.com/NexLaw+Video+-+Legislation+Search_Jan+24.mp4' },
        ];
        this.currentVideo = this.videos[0]; // Set first video as default
        this.isLoading = false;
      }, 1000);
    },

    // Set the Current Video for Playback
    setCurrentVideo(video) {
      this.currentVideo = video;
      this.$refs.videoPlayer.load(); // Reload video source
    },

    // Move to the Next Video
    nextVideo() {
      const currentIndex = this.videos.findIndex(video => video.id === this.currentVideo.id);
      const nextIndex = (currentIndex + 1) % this.videos.length;
      this.setCurrentVideo(this.videos[nextIndex]);
    },

    // Get Video Source Path
    getVideoSrc(file) {
      return file.startsWith('http') ? file : `path_to_video_files/${file}`;
    },

    // Handle Video Playback Errors
    handleVideoError() {
      console.error('Error loading video:', this.currentVideo.title);
      alert('Sorry, the video could not be loaded. Please try another one.');
      this.nextVideo();
    }
  }
};
</script>
<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--interface-grey);
}
.page-content{
  padding:0;
}

/* Navigation Bar */
.nav {
  width: 100%;
  padding: 20px;
  background: var(--dark-blue);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.nav .title {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #FFFFFF !important;
  margin-bottom: 5px;
}

.nav p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF !important;
}

/* Main Content */
.content {
  display: flex;
  width: 100%;
  max-width: 940px;
  padding-top: 10%;
}

/* Sidebar */
.sidebar {
  width: 284px;
  margin: 20px;
}

.sidetext {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--neutral-black);
  margin-bottom: 10px;
}

.c_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-grey);
  margin-bottom: 20px;
}

.list {
  margin-top: 20px;
}

.list .con_v {
  display: flex;
  align-items: top;
  justify-content: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.2s;
}
.con_v span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(227, 229, 235, 0.6);
  color: var(--dark-grey);
  margin-right: 15px;
  flex-shrink: 0;
}
.con_v:hover span {
  color: #FFFFFF;
  background: var(--primary) !important;
  flex-shrink: 0;
}

.con_v:hover .v-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: var(--primary) !important;
}

.v-title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 4px;
  color: var(--dark-grey) !important;
}
.sub-title{
  color: var(--dark-grey) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

/* Video Section */
.video-section {
  flex-grow: 1;
  padding: 15px;
  margin-left: 20px;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15%;
}

.video-display {
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-display p {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10%;
  color: var(--neutral-black);
}

video {
  max-height: 324px;
  max-width: 576px;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

button {
  padding: 10px 20px;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background-color: var(--hover-dark-blue);
}
.video-item {
  display: flex;   
  flex-direction: column; 
  justify-content: center;   
  align-items: center;    
  text-align: center;  
}
.next-btn {
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 10px 20px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  background: var(--primary) !important;
  height: 32px;
}

.next-text {
  margin-right: 8px;
}

</style>

